<template>
  <div class="safe-container" ref="container">
    <el-carousel ref="slideCarousel" :interval="5000" arrow="always" indicator-position="outside" 
    :height="bannerHeight1.gd+'px'" style="overflow-y: auto;">
      <el-carousel-item>
        <div style="display: flex;width:90%;justify-content: center;max-width:900px;margin: 10px auto;">
          <img :src="require('../../Images/dqms.png')" style="width:90%;max-width:660px;min-width:100px;margin:10px;">
        </div>
      </el-carousel-item>
      <el-carousel-item v-for="(item,index) in imagelists" :key="index">
        <div style="display: flex;flex-wrap: wrap;justify-content:space-between;width:90%;max-width:900px;margin: 10px auto;">
          <div :style="bannerHeight1.bl==2?'width:42%;':'width:32%;'+' min-width:150px;max-width:260px;flex-grow:1'" 
          v-for="(citem,cindex) in item" :key="cindex">
            <img :src="require('../../Images/'+citem)" class="image" style="width:90%;margin:10px;">
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      bannerHeight:540,
    }
  },
  computed: {

    // 当前 tab 项的 name
    bannerHeight1() {
      let wd=this.$store.getters.GettabBarInfo("tabBarWidth")*0.9;
      if (wd>900) wd=900
      let gd=this.$store.getters.GettabBarInfo("tabBarHeight")-80;
      let ls=2;
      if (wd>gd){
        ls=3;
      }else{
        if (wd*3/2>gd) gd=wd*3/2
      }
      console.log(ls,wd,gd)
      return {"ls":ls,"gd":gd}
    },
  },
  methods: {
    initMemuCollapse() {
      let screenWidth = document.body.clientWidth;
      if (
        (screenWidth <= 620 && this.isWideScreen) ||
        (screenWidth > 620 && !this.isWideScreen)
      ) {
        this.isWideScreen = !this.isWideScreen;
      }
      let p = navigator.platform;
      let u = navigator.userAgent;
      let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      //console.log(p,u);
      if (is_android) {
        //Android终端
        this.isPc = false;
      } else if (is_ios) {
        //IOS终端
        this.isPc = false;
      } else if (
        p == "Win32" ||
        p == "Win64" ||
        p == "MacPPC" ||
        p == "MacIntel" ||
        p == "X11" ||
        p == "Linux i686"
      ) {
        this.isPc = true;
      } else {
        this.isPc = false;
      }
      //console.log(this.isPc?'pc环境':'Mobile环境');
    },
    imgcol() {
      let imgcol = this.$refs.container.offsetWidth;
      if (imgcol<900){
        this.bannerHeight=810;
      }else{
        this.bannerHeight=550;
      }
      //console.log(this.bannerHeight,this.$refs.container.offsetWidth,this.$refs.container.clientWidth);
    },
    	// 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.el-carousel__container');
      var startPoint = 0;
      var stopPoint = 0;
      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        console.log("1：" + startPoint);
        console.log("2：" + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },

  },
  created() {
    this.imagelists=[];
    for (var i=0;i<6;i++){
      let t=[];
      for (var j=1;j<7;j++){
        t.push("aqzs"+i+j+".jpg");
      }
      this.imagelists.push(t);
    }
    
    
  },
  mounted() {
    window.onresize = () => {
      this.imgcol();
    };
    this.imgcol();
    this.slideBanner();
  },
}
</script>
<style scoped>
.safe-container {
  width: 100%;
  height: 100%;
  background-color: #475669;
}
.el-carousel__item11 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 100px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  </style>

